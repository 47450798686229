export function ease(x: number) {
  return 1 - Math.pow(1 - x, 4)
}

export function animate(
  tick: (x: number) => void,
  done: () => void,
  duration = 400,
  timing = (x: number) => x,
) {
  const start = performance.now()

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration
    if (timeFraction > 1) timeFraction = 1

    const progress = timing(timeFraction)

    tick(progress)

    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    } else {
      done()
    }
  })
}