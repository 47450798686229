import { animate, ease } from '~/utils/animation'

export function pageTransition(direction: -1 | 1) {
  return {
    onEnter: function (node: any, done: any) {
      if (direction === 1) {
        node.style.transform = `translateX(100%)`
        node.style.zIndex = '1'

        animate(
          (progress) => {
            const translateX = Math.max((1 - progress) * 100, 0)
            node.style.transform = `translate(${translateX}%)`
          },
          done,
          400,
          ease,
        )
      } else {
        animate(() => {}, done, 400)
      }
    },
    onLeave: function (node: any, done: any) {
      if (direction === 1) {
        node.style.zIndex = '1'

        animate(
          (progress) => {
            const translateX = Math.max(progress * 100, 0)
            node.style.transform = `translateX(${translateX}%)`
          },
          done,
          400,
          ease,
        )
      } else {
        animate(() => {}, done, 400)
      }
    },
  }
}
