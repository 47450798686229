import { pageTransition } from '~/utils/pageTransition'

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) {
    if (to.meta.level === undefined || from.meta.level === undefined) {
      return
    }

    from.meta.layoutTransition = pageTransition(
      // @ts-ignore
      to.meta.level < from.meta.level ? -1 : 1,
    )

    to.meta.layoutTransition = pageTransition(
      // @ts-ignore
      to.meta.level < from.meta.level ? 1 : -1,
    )
  }
})
